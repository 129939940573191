import React from 'react'
import yds from '../../../Assets/images/yds_logo.png'
import { Bars  } from 'react-loader-spinner'
function Loader() {
  return (
    <div>
         <div className="pre-loader">
			<div className="pre-loader-box">
				<div className="loader-logo">
					{/* <img src={yds} width={'200px'} height={'200px'} alt="" /> */}
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
      <Bars 
        height={80}
        width={80}
        color="#d43e01"
        ariaLabel="loading"
        // Additional props as needed
      />
    </div>
				</div>
	{/*
				<div className="loader-progress" id="progress_div">
				 <div className="bar" id="bar1"></div> 
					
				</div>*/}
				{/* <div className="percent" id="percent1">0%</div>
				<div className="loading-text">Loading...</div> */}
		
			</div>
		</div>
    </div>
  )
}

export default Loader
