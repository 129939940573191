export const handleDynamicRequest = async (method, url, requestBody, wrongResponse, rightResponse) => {
    try {
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: method !== 'GET' && method !== 'HEAD' ? JSON.stringify(requestBody) : undefined,
        };

        const response = await fetch(url, options);
        const data = await response.json();
        
        setTimeout(() => {
            if (response.ok) {
                rightResponse(data);
            } else {
                wrongResponse();
            }
        }, 100);
    } catch (error) {
        console.error('API Error:', error);
    }
};



export const handleDynamicRequestHeader = async (method, url, requestBody,token, wrongResponse, rightResponse) => {
    try {
         const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
               'Authorization':token
            },
            body: method !== 'GET' && method !== 'HEAD' ? JSON.stringify(requestBody) : undefined,
        });
       const data = await response.json();
        setTimeout(()=>{
            if (!response.ok) {
                wrongResponse();
            }
           else if (response.ok) {
            if(data){
                rightResponse(data);
            }
            }
        });
        
    } catch (error) {
        console.error('API Error:', error);
    }
};

export const handleDynamicRequestHeaderMulter = async (method, url, formData,token, wrongResponse, rightResponse) => {
    try {
        const response = await fetch(url, {
            method: method,
            headers: {

                'Authorization': token
            },
            body: formData
        });
        

        const data = await response.json();

        if (!response.ok) {
            wrongResponse();
        } else {
            rightResponse(data);
        }
    } catch (error) {
        console.error('API Error:', error);
    }
};
