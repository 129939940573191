import React, { useState, useEffect } from 'react'
import { BASE_URL, SetAlert, handleDynamicRequest, handleDynamicRequestHeader, handleDynamicRequestHeaderMulter } from '../../Common/CommonExport';
import Navbar from '../../Common/Navbar/Navbar'
import Sidebar from '../../Common/Sidebar/Sidebar'
import Loader from '../../Common/Loader/Loader'
import Login from '../Authentication/Login'
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
function Dashboard({children}) {
	const currentDate = new Date();
  
	const currentYear = new Date().getFullYear();
	  
	return (
		<div>
		<Loader />
		<Navbar />
		<Sidebar />
		<div className="mobile-menu-overlay"></div>
		<div className="main-container pt-5"  >
				<div className="pd-ltr-20 xs-pd-20-10" style={{ minHeight: '83vh' }}>
				<div className="d-flex justify-content-between pb-3">
            
          
        </div>
					{children}
				
				</div>
				<div className="footer-wrap pd-20 mb-20 card-box mb-4">
					&copy;&nbsp;{currentYear}&nbsp;&nbsp;<a href="https://codelaksh.in" target="_blank">CodeLaksh</a>&nbsp;&nbsp;All Rights Reserved.
				</div>
			</div>
		</div>
	)
}

export default Dashboard
