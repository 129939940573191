import React from 'react'
import yds from '../../../Assets/images/yds_logo.png'
function Sidebar() {
	const menuItems = [
		{
			text: 'Home',
			icon: 'bi-house',
			link: '/dashboard',

		},
		{
			text: 'Ekadashi',
			icon: 'bi-book',
			link: '/ekadashi',
		},
		{
			text: 'Bhajan',
			icon: 'bi-music-note-beamed',
			link: 'bhajan',
		},
		{
			text: 'Step Count',
			icon: 'bi-bar-chart-steps',
			link: 'step-count',
		},

		{
			text: 'Birthday',
			icon: 'bi-balloon',
			link: '/birthday',
		},
		{
			text: 'Notification',
			icon: 'bi-bell',
			link: '/notification',
		},
		{
			text: 'Charts',
			icon: 'bi-clipboard-data',
			link: 'weekly-charts',
		},

	];
	return (
		<>
			<div className="left-side-bar">
				<div className="brand-logo">
					<a href="/dashboard">
						<img src={yds} height={'50px'} width={'50px'} alt="" className="dark-logo" />
						<p style={{color:'black', marginBottom:0}}>YDS</p>
					</a>
					
					<div className="close-sidebar" data-toggle="left-sidebar-close">
						<i className="ion-close-round"></i>
					</div>
				</div>
				<div className="menu-block customscroll" style={{ boxShadow: '5px 0px 5px rgba(0, 0, 0, 0.1)'}}>
					<div className="sidebar-menu">
						<ul id="accordion-menu">
							{menuItems.map(item => (
								<li className="dropdown" key={item.text}>
									<a href={item.link} className="dropdown-toggle">
										<span className={`micon bi ${item.icon}`}></span>
										<span className="mtext">{item.text}</span>
									</a>

								</li>
							))}
						</ul>

					</div>
				</div>
			</div>

		</>

	)
}

export default Sidebar
