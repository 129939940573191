import React, { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../../Common/AppConfig';
import SetAlert from '../../Common/SetAlert';
import { useDispatch } from 'react-redux';
import { handleDynamicRequest } from '../../Common/DyanamicRequest';
import { setUserId, setUserRole, setUserName, setJwtToken, setU_photo } from '../../../Store/Role/action';
import swami from '../../../Assets/images/swami.png';
import yds from '../../../Assets/images/yds_logo.png'
function Login() {
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [formData, setFormData] = useState({email: '', password: ''});
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, password: value });
    };

    const handleEmailSubmit = async () => {
        const requestBody = { email: formData.email, password: formData.password };
        const method = 'POST';
        const url = `${BASE_URL}/login`;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Wrong Credentials' });
        }
        const rightResponse = (data) => {
            if (data) {
             
              const sessionToken = data.data.jwtToken;
                const sessionExpiration = new Date().getTime() + 5000 * 60 * 5000;
                sessionStorage.setItem('sessionToken', sessionToken);
                sessionStorage.setItem('sessionExpiration', sessionExpiration);
                dispatch(setUserId(data.data.id));
                dispatch(setUserRole(data.data.roleId));
                dispatch(setUserName(data.data.employee_name));
                dispatch(setJwtToken(data.data.jwtToken));
                dispatch(setU_photo(data.data.photo));
                setTimeout(()=>{
                    window.location.reload();
                 },100);
                 setAlertMessage({ success: '', error: '' });
                 console.log(data)
            }
        }
        handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse)
 
    }
    return (
        <div>
            <div className="login-header box-shadow mb-5">
                
            </div>
            <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-7">
                            <img src={swami} alt="" />
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <div className="login-box bg-white box-shadow border-radius-10">
                                <div className="login-title">
                                    <h2 style={{color:'#d43e01'}} className="text-center "><img src={yds} width={'100px'} height={'100px'} alt="" /></h2>
                                    <h2 style={{color:'#d43e01'}} className="text-center ">Yogi Divine Society</h2>
                                </div>
                                <div >
                                    <h5 className="text-center text-primary" style={{marginBottom:0}}>
                                    <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                                    </h5>
                                </div>
                                <form>
                                    <div className="select-role">
                                    </div>
                                    <div className="input-group custom">
                                        <input type="text" className="form-control form-control-lg" 
                                        name="email"
                                        onChange={handleInputChange} 
                                        value={formData.email} placeholder="Username"/>
                                        <div className="input-group-append custom">
                                            <span className="input-group-text">
                                                <i className="icon-copy dw dw-user1"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="input-group custom">
                                        <input type="password" className="form-control form-control-lg"  
                                        value={formData.password}
                                        onChange={handlePasswordChange} 
                                        placeholder="Password"/>
                                        <div className="input-group-append custom">
                                            <span className="input-group-text"><i className="dw dw-padlock1"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row pb-30">
                                        <div className="col-6">
                                      
                                        </div>
                                      
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="input-group mb-0">
                                                <a className="btn btn-primary btn-lg btn-block" style={{backgroundColor:'#d43e01', border:'none'}} href='#' onClick={handleEmailSubmit} >
                                                    Sign In</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
