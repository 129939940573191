import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import ActionDropdown from '../Dropdown/ActionDropdown';
import { handleStatusChange, handleDelete } from '../ActiveStatusChange';
import { BASE_URL,handleDynamicRequestHeader } from '../CommonExport';
import SetAlert from '../SetAlert';
import { Button, Input, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
function DataTables(props) {
  
  const userToken = useSelector((state) => state.roleReducer.jwtToken);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchText, setSearchText] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const renderActionsDropdown = (row) => (
    <ActionDropdown row={row} toggleDropdown={toggleDropdown} handleAction={handleAction} isActive={row.isActive} showEdit={props.showEdit}/>
  );
  const toggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
};
const handleAddUser = () => {
  
  toggleAddModal();
};
  const toggleDropdown = (row) => {
    row.dropdownOpen = !row.dropdownOpen;
    setDropdownOpen(!dropdownOpen);
  };

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    toggleEditModal();
    props.setFormData(user);
  };


  const handleDeleteClick =async (id) => {
    const requestBody = {};
    const method = 'DELETE';
    const url =`${BASE_URL}/${props.subUrl}/${id}`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    }
       const rightResponse = (data) => {
     
    }
    await handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
    props.handleMainMenu();
 ;
  };

  const handleActiveClick = async (id) => {
    const requestBody = {isActive:1};
    const method = 'PUT';
    const url =`${BASE_URL}/${props.subUrl}/status/${id}`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    }
       const rightResponse = (data) => {
     
    }
    await handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
    props.handleMainMenu();
  };

  const handleDeactiveClick =async (id) => {
    const requestBody = {isActive:0};
    const method = 'PUT';
    const url =`${BASE_URL}/${props.subUrl}/status/${id}`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    }
       const rightResponse = (data) => {
     
    }
    await handleDynamicRequestHeader(method, url, requestBody, userToken, wrongResponse, rightResponse);
    props.handleMainMenu();
  };

  const handleAction = (action, row) => {
    if (action.toLowerCase() === 'edit') {
      handleEdit(row);
    } else if (action.toLowerCase() === 'delete') {
      handleDeleteClick(row.id);
    } else if (action.toLowerCase() === 'active') {
      handleActiveClick(row.id);
    } else if (action.toLowerCase() === 'deactive') {
      handleDeactiveClick(row.id);
    }
  };

  const filteredData = searchText
    ? props.filteredData.filter((row) =>
        Object.values(row).some((value) => String(value).toLowerCase().includes(searchText.toLowerCase()))
      )
    : props.filteredData;

  // let updatedColumns = [...props.columns, 
  //   {
  //   name: 'Action',
  //   cell: (row) => renderActionsDropdown(row),
  //   ignoreRowClick: true,
  //   allowoverflow: true,
  //   button: 'true',
  // }];

  let updatedColumns = [...props.columns];

if (props.dropdownShow) {
  updatedColumns.push({
    name: 'Action',
    cell: (row) => renderActionsDropdown(row),
    ignoreRowClick: true,
    allowoverflow: true,
    button: 'true',
  });
}



  return (
    <div className='row'>
    <div className="col-8 mt-2">
 
    </div>
    <div className="col-4 mt-2">
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <Input
          type='text'
          placeholder='Search...'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ borderRadius: '20px', marginRight: '10px', border: '1px solid #d4d9de', height: '35px',width:'80%' , paddingLeft: '10px' }}
        />
        <Button className="primary" onClick={handleAddUser}>Add</Button>
      </div>
    </div>
    <div className="col-12 mt-2">
      <DataTable
        className='table-nowrap'
        columns={updatedColumns}
        data={filteredData}
        pagination
        highlightOnHover
        noHeader
        responsive
        keyField="id"
      />
    </div>

    <Modal isOpen={addModalOpen} toggle={toggleAddModal}  size="lg">
    <ModalHeader>Add {props.subUrl}</ModalHeader>
    <div className="row">
      <div className='col-12 pl-5 pr-5 pt-3' >
      {props.handleForm()} 

      </div>
    </div>
    <ModalFooter className='mt-3 mb-3'>
  
  <Button onClick={toggleAddModal}  color="secondary mr-3">Cancel</Button>{' '}
  <Button onClick={() => { props.handleAdd(); toggleAddModal(); }} color="primary mr-2">Edit</Button>


  </ModalFooter>
    </Modal>

    <Modal isOpen={editModalOpen} toggle={toggleEditModal}  size="lg">
    <ModalHeader>Edit {props.subUrl}</ModalHeader>
    <div className="row">
      <div className='col-12 pl-5 pr-5 pt-3' >
      {props.handleEditForm()} 

      </div>
    </div>
    <ModalFooter className='mt-3 mb-3'>
  
  <Button onClick={toggleEditModal}  color="secondary mr-3">Cancel</Button>{' '}
  <Button onClick={() => { props.handleEdit(); toggleEditModal(); }} color="primary mr-2">Edit</Button>
 
  </ModalFooter>
    </Modal>
  </div>
  )
}

export default DataTables
