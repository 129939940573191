import React from 'react'
import Navbar from '../../Common/Navbar/Navbar'
import Sidebar from '../../Common/Sidebar/Sidebar'
import Loader from '../../Common/Loader/Loader'
function Birthday() {
  return (
    <div>
      		<Loader />
		<Navbar />
		<Sidebar />
		<div className="mobile-menu-overlay"></div>
    </div>
  )
}

export default Birthday
