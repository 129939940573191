import React from 'react'
import { ButtonDropdown, Button, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IoMdArrowDropdown } from "react-icons/io";
function ActionDropdown(props) {
  return (
    <ButtonDropdown style={{border:'none'}} direction="up" isOpen={props.row.dropdownOpen} toggle={() => props.toggleDropdown(props.row)}>
    <Button id="caret" color="primary" size="sm" className="custom-button">
      Actions
    </Button>
    <DropdownToggle caret color="primary" size="sm" className="custom-caret">
    <IoMdArrowDropdown />
    </DropdownToggle>
    <DropdownMenu className="custom-dropdown-menu">
      {props.showCheckedIn ? (
        props.isScanned ? (<DropdownItem onClick={() => props.handleAction('checkedout', props.row)}>Checked-Out</DropdownItem>) : (
          <DropdownItem onClick={() => props.handleAction('checkedin', props.row)}>Checked-In</DropdownItem>
        )
      ) : null}
  {props.isActive ? (
        <DropdownItem onClick={() => props.handleAction('deactive', props.row)}>Deactivate</DropdownItem>
      ) : (
        <DropdownItem onClick={() => props.handleAction('active', props.row)}>Activate</DropdownItem>
      )}
      {props.showEdit ? (
        <DropdownItem onClick={() => props.handleAction('edit', props.row)}>Edit</DropdownItem>
      ) : null}
      <DropdownItem onClick={() => props.handleAction('delete', props.row)}>Delete</DropdownItem>
    </DropdownMenu>
  </ButtonDropdown>
  )
}

export default ActionDropdown
