import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client

import App from './App';
import './Assets/Main.scss'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './Store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
const userRole = localStorage.getItem('userRole');

const root = createRoot(document.getElementById('root'));

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App userRole={userRole} />
    </PersistGate>
  </Provider>
);

root.render(app);



