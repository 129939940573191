import React, { useState, useEffect } from 'react';
import Navbar from '../../Common/Navbar/Navbar';
import Sidebar from '../../Common/Sidebar/Sidebar';
import Loader from '../../Common/Loader/Loader';
import DataTables from '../../Common/Data Tables/DataTables';
import { BASE_URL, SetAlert, handleDynamicRequest,handleDynamicRequestHeader } from '../../Common/CommonExport';
import Dashboard from '../Dashboard/Dashboard';
import { useSelector } from 'react-redux';

function Notification() {
  const [bhajan, setBhajan] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userToken = useSelector((state) => state.roleReducer.jwtToken);

  const columns = [
    { name: 'Sr No', selector: (row) => row.id, sortable: true },
    { name: 'Name', selector: (row) => row.name, sortable: true },
    { name: 'Type', selector: (row) => row.type, sortable: true },
    { name: 'Message', selector: (row) => row.message, sortable: true },
    { name: 'Created Date', selector: (row) => row.createdDate, sortable: true }
  ];

  const handleMainMenu = () => {
    const requestBody = {};
    const method = 'GET';
    const url = `${BASE_URL}/notification`;
    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    }
    console.log(url)
    const rightResponse = (data) => {
      if (data) {
        console.log(data.data, 'notification')
        setBhajan(data.data)
      }
    }
    handleDynamicRequestHeader(method, url, requestBody,userToken, wrongResponse, rightResponse)
  }
console.log(bhajan)
  useEffect(() => {
    handleMainMenu();

  }, []);
  return (
    <div>
      <Loader />
      <Navbar />
      <Sidebar />
      <div class="mobile-bhajan-overlay"></div>
      <Dashboard>
        <div className="min-height-200px mt-2">
          <div className="page-header" style={{ height: '70vh' }}>
            <div className="row">
              <DataTables columns={columns} filteredData={bhajan} />
            </div>
          </div>
        </div>
      </Dashboard>
    </div>
  )
}

export default Notification


