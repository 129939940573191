import { SET_USER_ROLE, SET_USER_ID,  SET_USER_NAME, SET_JWTTOKEN,SET_U_PHOTO, LOGOUT} from "./actionTypes";


export const setUserRole = (role) => ({
    type: SET_USER_ROLE,
    payload: role,
  });

  
export const setUserId = (_id) => ({
    type: SET_USER_ID,
    payload: _id,
  });

  
export const setUserName = (fullName) => ({
  type: SET_USER_NAME,
  payload: fullName,
});

export const setJwtToken = (jwtToken) => ({
  type: SET_JWTTOKEN,
  payload: jwtToken,
});

export const setU_photo = (u_photo) => ({
  type: SET_U_PHOTO,
  payload: u_photo,
});

export const setLogout = (logout) => ({
  type: LOGOUT,
  payload: logout,
});