import Login from "../Components/Pages/Authentication/Login";
import Bhajan from "../Components/Pages/Bhajan/Bhajan";
import Birthday from "../Components/Pages/Birthday/Birthday";
import Dashboard from "../Components/Pages/Dashboard/Dashboard";
import Home from "../Components/Pages/Dashboard/Home";
import Ekadashi from "../Components/Pages/Ekadashi/Ekadashi";
import Notification from "../Components/Pages/Notification/Notification";
import StepCount from "../Components/Pages/Step Count/StepCount";
import WeeklyChart from "../Components/Pages/Weekly Chart/WeeklyChart";


const authProtectedRoutes = [
    { path: "/dashboard", component: Home },
    { path: "/bhajan", component: Bhajan },
    { path: "/birthday", component: Birthday },
    { path: "/ekadashi", component: Ekadashi },
    { path: "/step-count", component: StepCount },
    { path: "/weekly-chart", component: WeeklyChart },
    { path: "/notification", component: Notification },

];


const publicRoutes = [

	{ path: "/", component: Login }

	
];

export { authProtectedRoutes,  publicRoutes };
